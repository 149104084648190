import { faFacebookF, faInstagram, faLinkedin, faXTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { i18n } from 'next-i18next.config';
import { FC, MouseEventHandler, useCallback, useEffect, useRef, useState } from 'react';

import { Image } from '@commerce/types/common';
import { validateNetsuiteEmail } from '@components/checkout/validations';
import DownloadApp from '@components/common/DownloadApp/DownloadApp';
import RichText from '@components/common/RichText/RichText';
import Accordion from '@components/ui/Accordion/Accordion';
import Button from '@components/ui/Button/Button';
import Input from '@components/ui/Input/Input';
import Link from '@components/ui/Link';
import Text from '@components/ui/Text/Text';
import callApiRoutes from '@lib/api/call-api-routes';
import { pushToGTMDataLayer } from '@lib/gtag';
import { isUSLocale as isUSLocaleFn } from '@lib/locales';
import { isLife360Enabled } from '@lib/storefront';

import style from './Footer.module.scss';

interface LinkElements {
  id: string;
  key: string;
  value: string;
}
interface LinkListContent {
  title: string;
  listElements: LinkElements[];
  showCookiesSettingsButton: boolean;
  showDoNotSellPersonalInfoButton: boolean;
}

export interface DownloadButtons {
  image: Image;
  name: string;
  url: string;
}
interface DownloadSectionContent {
  title: string;
  buttons: DownloadButtons[];
}

export interface FooterModuleContent {
  title?: string;
  linkListContent?: LinkListContent[];
  downloadSectionContent?: DownloadSectionContent;

  footerText?: string;
}

interface Props {
  data: FooterModuleContent | null;
}

export const EmailOptInForm = ({ locale }: { locale: string }) => {
  const { t } = useTranslation('common');

  const [email, setEmail] = useState('');
  const [loading] = useState(false);

  const [disabled, setDisabled] = useState(false);

  const [inputError, setInputError] = useState<boolean>();
  const [inputSuccess, setInputSuccess] = useState<boolean>();

  const [serverError, setServerError] = useState(false);

  const handleValidation = useCallback(() => {
    // Unable to send form unless fields are valid.
    setDisabled(!validateNetsuiteEmail(email).success || email === '');
  }, [email]);

  const handleEmailOptIn = async (ev: React.SyntheticEvent<EventTarget>) => {
    ev.preventDefault();
    setDisabled(true);

    try {
      pushToGTMDataLayer({ emailSignUpEmail: email });
      const url = typeof window !== 'undefined' ? window.location?.href : '';
      const result = (
        await callApiRoutes(`/api/braze/email-opt-in?locale=${locale}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email, url, source: 'newsletter' }),
        })
      ).data;

      if (result.message === 'success') {
        pushToGTMDataLayer({ emailSignUp: '' });
        setInputSuccess(true);

        if (inputError) {
          setInputError(false);
        }

        if (serverError) {
          setServerError(false);
        }

        setTimeout(() => {
          setInputSuccess(false);
        }, 8000);
      }
    } catch {
      setServerError(true);

      setTimeout(() => {
        setServerError(false);
      }, 8000);

      if (inputSuccess) {
        setInputSuccess(false);
      }
    } finally {
      setEmail('');
      setDisabled(!validateNetsuiteEmail(email).success || email === '');
    }
  };

  useEffect(() => {
    handleValidation();
  }, [handleValidation]);

  return (
    <form className={style.emailOptInForm}>
      <div className={style.emailOptInFormContainer}>
        <div className={style.emailOptInField}>
          <Input
            type="text"
            className={style.emailOptInInput}
            title="Email"
            placeholder={t('footer.enterYourEmail')}
            value={email}
            onChange={(value: string) => setEmail(value.trim())}
          />
          <Button
            data-testid="button"
            aria-label={t('footer.signUp')}
            className={style.emailOptInButton}
            type="submit"
            onClick={handleEmailOptIn}
            loading={loading}
            disabled={disabled}
          >
            <FontAwesomeIcon
              className={style.emailOptInButtonIcon}
              icon={faArrowRight}
              title="Sign up icon"
              titleId="emailOptInButtonIcon"
              aria-hidden
            />
            <span className={style.emailOptInButtonLabel}>{t('footer.signUp')}</span>
          </Button>
        </div>

        {inputError && (
          <span data-testid="error-message" className={style.inputError}>
            {t('footer.enterValidEmail')}
          </span>
        )}

        {serverError && (
          <span data-testid="error-message" className={style.inputError}>
            {t('footer.pleaseTryAgain')}
          </span>
        )}

        {inputSuccess && (
          <span data-testid="success-message" className={style.inputSuccess}>
            {t('footer.thankYouForSignUp')}
          </span>
        )}
      </div>
    </form>
  );
};

const EmailOptInSection = ({ locale, title }: { locale: string; title?: string }) => {
  return (
    <div className={style.emailOptIn}>
      <Text
        variant="heading-2"
        className={cn(style.heading, 'text-center lg:text-right')}
        weight="bold"
        color="var(--white)"
      >
        {title}
      </Text>

      <EmailOptInForm locale={locale} />
    </div>
  );
};

const SocialMediaSection = () => {
  const { t } = useTranslation('common');

  return (
    <div className={style.socialMedia}>
      <Text
        variant="heading-2"
        className={cn(style.heading, 'text-center lg:text-right')}
        weight="bold"
        color="var(--white)"
      >
        {t('footer.connectWithUs')}
      </Text>
      <ul className={style.socialMediaLinksContainer}>
        <li className={style.socialMediaItem}>
          <a
            href={isLife360Enabled() ? 'https://x.com/life360' : 'https://twitter.com/TheTileApp'}
            target="_blank"
            rel="noreferrer"
            title={`Twitter ${t('common:screenReader.openInNewTab')}`}
            className={style.socialMediaLink}
            aria-label={`Twitter ${t('common:screenReader.openInNewTab')}`}
          >
            <FontAwesomeIcon
              className={style.socialMediaLinkIcon}
              icon={faXTwitter}
              title={`Twitter ${t('common:screenReader.openInNewTab')}`}
              titleId="twitterFooterIcon"
              aria-hidden
            />
          </a>
        </li>

        <li className={style.socialMediaItem}>
          <a
            href={
              isLife360Enabled()
                ? 'https://www.youtube.com/@Life360Inc'
                : 'https://www.youtube.com/channel/UCsmCffQudH3CTOm5c4KiadQ'
            }
            target="_blank"
            rel="noreferrer"
            title={`YouTube ${t('common:screenReader.openInNewTab')}`}
            className={style.socialMediaLink}
            aria-label={`YouTube ${t('common:screenReader.openInNewTab')}`}
          >
            <FontAwesomeIcon
              className={style.socialMediaLinkIcon}
              icon={faYoutube}
              title={`YouTube ${t('common:screenReader.openInNewTab')}`}
              titleId="YoutubeFooterIcon"
              aria-hidden
            />
          </a>
        </li>

        <li className={style.socialMediaItem}>
          <a
            href={isLife360Enabled() ? 'https://www.facebook.com/life360' : 'https://www.facebook.com/tile'}
            target="_blank"
            rel="noreferrer"
            title={`Facebook ${t('common:screenReader.openInNewTab')}`}
            className={style.socialMediaLink}
            aria-label={`Facebook ${t('common:screenReader.openInNewTab')}`}
          >
            <FontAwesomeIcon
              className={style.socialMediaLinkIcon}
              icon={faFacebookF}
              title={`Facebook ${t('common:screenReader.openInNewTab')}`}
              titleId="fbFooterIcon"
              aria-hidden
            />
          </a>
        </li>

        <li className={style.socialMediaItem}>
          <a
            href={isLife360Enabled() ? 'https://www.instagram.com/life360' : 'http://instagram.com/tile'}
            target="_blank"
            rel="noreferrer"
            title={`Instagram ${t('common:screenReader.openInNewTab')}`}
            className={style.socialMediaLink}
            aria-label={`Instagram ${t('common:screenReader.openInNewTab')}`}
          >
            <FontAwesomeIcon
              className={style.socialMediaLinkIcon}
              icon={faInstagram}
              title={`Instagram ${t('common:screenReader.openInNewTab')}`}
              titleId="instagramFooterIcon"
              aria-hidden
            />
          </a>
        </li>

        {isLife360Enabled() && (
          <li className={style.socialMediaItem}>
            <a
              href="https://www.linkedin.com/company/life360"
              target="_blank"
              rel="noreferrer"
              title={`LinkedIn ${t('common:screenReader.openInNewTab')}`}
              className={style.socialMediaLink}
              aria-label={`Instagram ${t('common:screenReader.openInNewTab')}`}
            >
              <FontAwesomeIcon
                className={style.socialMediaLinkIcon}
                icon={faLinkedin}
                title={`LinkedIn ${t('common:screenReader.openInNewTab')}`}
                titleId="linkedInFooterIcon"
                aria-hidden
              />
            </a>
          </li>
        )}
      </ul>
    </div>
  );
};

const DownloadAppSection = ({ downloadSectionContent }: { downloadSectionContent: DownloadSectionContent }) => {
  const { t } = useTranslation('common');
  const { title, buttons } = downloadSectionContent;

  return (
    <div className={style.downloadApp}>
      <Text
        variant="heading-2"
        className={cn(style.heading, 'text-center lg:text-right')}
        weight="bold"
        color="var(--white)"
      >
        {title || t('footer.download')}
      </Text>
      <DownloadApp buttons={buttons} />
    </div>
  );
};

const CopyrightSection = () => {
  const { t } = useTranslation('common');

  return (
    <p
      className={cn(style.text, 'mt-m lg:mt-0')}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: t(isLife360Enabled() ? 'footer.l360Copyright' : 'footer.copyright', {
          year: `${new Date().getFullYear()}`,
        }),
      }}
    />
  );
};

const CookieTrackingPreferences = ({
  showCookies,
  showDoNotSell,
}: {
  showCookies: boolean;
  showDoNotSell: boolean;
}) => {
  const { t } = useTranslation('common');
  const cookiesRef = useRef<HTMLButtonElement>(null);

  if (!showCookies && !showDoNotSell) {
    return null;
  }

  const osanoConsentDialogOpen: MouseEventHandler<HTMLButtonElement> = (e) => {
    if (showCookies && window.Osano?.cm !== undefined) {
      e.preventDefault();
      window.Osano?.cm?.showDrawer();
    }
  };

  const handleDoNotSellClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    if (showCookies) {
      e.preventDefault();
      cookiesRef?.current?.click();
    }
  };

  return (
    <>
      {showCookies && (
        <li className="flex items-end">
          <button
            ref={cookiesRef}
            onClick={osanoConsentDialogOpen}
            type="button"
            className="cookie-consent-dialogue-btn"
          >
            {t('footer.cookiesSettings')}
          </button>
          <img
            className={style.privacy}
            src="/p_cookie_icon.png"
            alt={t('footer.privacyIcon')}
            aria-hidden
            loading="lazy"
            width={50}
            height={25}
          />
        </li>
      )}
      {showDoNotSell && (
        <>
          <li>
            <button
              className="text-left do-not-sell-btn min-h-10"
              aria-label={t('footer.doNotSellAriaLabel')}
              onClick={handleDoNotSellClick}
              type="button"
            >
              <Text variant="inline" html={t('footer.doNotSellPersonalInfo')} asElement="span" />
            </button>
          </li>
        </>
      )}
    </>
  );
};

const MenuSection = ({
  isUSLocale = true,
  linkListContent = [],
  ...props
}: {
  isUSLocale: boolean;
  linkListContent: LinkListContent[];
  className?: string;
}) => {
  const { t } = useTranslation('common');
  const isAbsolute = /^(?:[a-z]+:)?\/\//i;
  const renderLinkElements = (linkArray: Array<LinkElements>) =>
    !Array.isArray(linkArray)
      ? null
      : linkArray.map((linkElement: LinkElements) => {
          const { value: link, key: title } = linkElement;

          if (isAbsolute.test(link)) {
            return (
              <li key={title}>
                <a href={link} title={t('common:screenReader.openInNewTab')} target="_blank" rel="noreferrer">
                  {title}
                </a>
              </li>
            );
          }

          return (
            <li key={title}>
              <Link href={link}>{title}</Link>
            </li>
          );
        });

  return (
    <div className={props.className}>
      <div className="block lg:hidden">
        {linkListContent?.map(({ title, showCookiesSettingsButton, showDoNotSellPersonalInfoButton, listElements }) => (
          <div key={title}>
            <hr className={style.separator} />
            <Accordion key={title} className={style.linkListAccordion} header={title}>
              <ul>
                {renderLinkElements(listElements)}
                <CookieTrackingPreferences
                  showCookies={showCookiesSettingsButton}
                  showDoNotSell={showDoNotSellPersonalInfoButton && isUSLocale}
                />
              </ul>
            </Accordion>
          </div>
        ))}
        <hr className={style.separator} />
      </div>
      <div className={style.linkListContainer}>
        {linkListContent?.map(({ title, showCookiesSettingsButton, showDoNotSellPersonalInfoButton, listElements }) => (
          <div className={style.linkList} key={title}>
            <Text className={style.linkListTitle} asElement="h2">
              {title}
            </Text>
            <ul>
              {renderLinkElements(listElements)}
              <CookieTrackingPreferences
                showCookies={showCookiesSettingsButton}
                showDoNotSell={showDoNotSellPersonalInfoButton && isUSLocale}
              />
            </ul>
          </div>
        ))}
      </div>
      <CopyrightSection />
    </div>
  );
};

const Footer: FC<Props> = ({ data }) => {
  const { locale = i18n.defaultLocale } = useRouter();
  const isUSLocale = isUSLocaleFn(locale);
  const {
    linkListContent = [],
    title = '',
    downloadSectionContent = { title: '', buttons: [] },
    footerText = '',
  } = data ?? {};

  return (
    <footer className={style.footer} id="footer">
      <div className="container px-4 lg:px-6">
        <div className={style.footerWrapper}>
          <div className={style.contactSection}>
            <EmailOptInSection locale={locale} title={title} />
            <SocialMediaSection />
            <DownloadAppSection downloadSectionContent={downloadSectionContent} />
          </div>

          <MenuSection className={style.menuSection} isUSLocale={isUSLocale} linkListContent={linkListContent ?? []} />
        </div>
        <RichText className={cn(style.text, 'mt-m lg:mt-l')} document={footerText} />
      </div>
    </footer>
  );
};

export default Footer;
